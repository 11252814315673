import React, { useEffect } from 'react'

const enableSelectpickerPaste = () => {
  window.$('select.selectpicker').on('loaded.bs.select', selectElement => {
    window.$(selectElement.target).parent()
      .find('.bs-searchbox')
      .find('input')[0]
      .onpaste = event => {
        const clipboardData = event.clipboardData.getData('text')
        const splitStr = clipboardData.includes('\r\n') ? '\r\n' : '\n'
        const paste = clipboardData.split(splitStr)
        const newValues = []
        const options = Array.from(selectElement.target.options)
        paste.forEach(pasteElement => {
          options.forEach(option => {
            if (pasteElement.toUpperCase() === option.text.toUpperCase())
              newValues.push(option.value)
          })
        })
        const previousValues = Array.from(selectElement.target.selectedOptions, option => option.value)
        window.$(selectElement.target).selectpicker('val', newValues.concat(previousValues))
        window.$(selectElement.target).selectpicker('render')
      }
  })
}

const SelectUserGroupField = ({ name, label, data, value, onChange, multiple }) => {
  useEffect(() => {
    window.$(`select[name="${name}"].selectpicker`).selectpicker('render')
    enableSelectpickerPaste()
  })

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select
        className="form-control selectpicker"
        name={name}
        id={name}
        defaultValue={value}
        multiple={multiple}
        data-live-search="true"
        data-live-search-placeholder="Rechercher..."
        data-actions-box
        onChange={onChange}
      >
        {
          Object.keys(data).map((key, index) => (
            <optgroup key={index} label={key}>
              {data[key].map(item => (
                <option key={item.id} value={item.id}>
                  {`${item.last_name} ${item.first_name}`}
                </option>
              ))}
            </optgroup>
          ))
        }
      </select>
    </div>
  )
}

export default SelectUserGroupField
