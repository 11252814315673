import React, { useEffect, useState } from 'react'

// Local import
import CollectForm from './CollectForm'
import Loader from './Loader'

const EditCollect = ({ collectId }) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  const [data, setData] = useState(null)

  useEffect(() => {
    fetch(`/collects/${collectId}.json`, { method: 'GET', headers })
      .then(response => response.json())
      .then(responseData => setData(responseData))
  }, [])

  return (
    <div className="card">
      <div className="card-header bg-light">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inline">
              <i className="fas fa-archive" />
              {' Edition d\'une collecte'}
            </h2>
          </div>
          <div className="col-md-6">
            <a className="btn btn-primary float-md-right mt-2 mt-md-0" href="/collects">
              <i className="fas fa-reply" />
              {' Retour'}
            </a>
          </div>
        </div>
      </div>
      <div className="card-body">
        {data ? <CollectForm data={data} /> : <Loader />}
      </div>
    </div>
  )
}

export default EditCollect
