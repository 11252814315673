import React from 'react'
import PropTypes from 'prop-types'

const PhotoGallerySection = ({ title, photosSize, url }) => (
  <div className="col-sm-12 col-md-9 col-lg-6 col-xl-3 mb-3">
    <div className="card text-center">
      <div className="card-body">
        <h5 className="card-title col-12 text-truncate" data-toggle="tooltip" title={title}>
          {title}
        </h5>
        <h5>
          <span className={`badge ${photosSize > 0 ? 'badge-success' : 'badge-light'} pb-0`}>
            {photosSize}
          </span>
          {photosSize > 1 ? ' photos' : ' photo'}
        </h5>
        <a className="btn btn-success" href={url}>
          Voir les photos
        </a>
      </div>
    </div>
  </div>
)

PhotoGallerySection.propTypes = {
  photosSize: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default PhotoGallerySection
