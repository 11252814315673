import React from 'react'

const SelectField = ({ name, label, data, value, errors, onChange, includeBlank, disabled }) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <select
      className={errors ? 'form-control is-invalid' : 'form-control'}
      name={name}
      id={name}
      defaultValue={value}
      disabled={disabled}
      onChange={onChange}
    >
      {includeBlank ? <option key={0} /> : null}
      {data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
    </select>
    {errors ? <small className="text-danger">{errors[0]}</small> : null}
  </div>
)

export default SelectField
