import React from 'react'

// Local import
import { updateErrors } from '../SectionField'

class Title extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.question.id,
      context: props.question.context,
      position: props.question.position,
      title: props.question.title || '',
      errors: props.question.errors || {}
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)

    return (
      this.state.title !== nextState.title ||
      this.state.position !== nextState.position ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleQuestionChange(this.state)
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="nested-fields ml-2 ml-md-4">
        <div className="d-flex">
          <div className="mr-auto">
            <i className="fas fa-arrows-alt c-pointer" {...this.props.dragHandleProps} />
            {' Titre '}
            <i
              className="fas fa-clone text-secondary c-pointer"
              onClick={this.props.handleQuestionDuplicate(this.state.id)}
            />
          </div>
          <i
            className="fas fa-trash danger-hover c-pointer"
            onClick={this.props.handleQuestionRemove(this.state.id)}
          />
        </div>
        <div className="form-group">
          <input
            className={`form-control ${this.state.errors.title ? 'is-invalid' : ''}`}
            type="text"
            name="title"
            id="title"
            value={this.state.title}
            placeholder="Titre..."
            onChange={this.handleChange}
          />
          {
            Boolean(this.state.errors.title) &&
            <small className="text-danger">
              {this.state.errors.title[0]}
            </small>
          }
        </div>
      </div>
    )
  }
}

export default Title
