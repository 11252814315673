import React from 'react'

// Local import
import { updateErrors } from '../SectionField'
import TextAreaField from '../BasicFields/TextAreaField'

class Description extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.question.id,
      context: props.question.context,
      position: props.question.position,
      description: props.question.description || '',
      errors: props.question.errors || {}
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)

    return (
      this.state.description !== nextState.description ||
      this.state.position !== nextState.position ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleQuestionChange(this.state)
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="nested-fields ml-2 ml-md-4">
        <div className="d-flex">
          <div className="mr-auto">
            <i className="fas fa-arrows-alt c-pointer" {...this.props.dragHandleProps} />
            {' Description '}
            <i
              className="fas fa-clone text-secondary c-pointer"
              onClick={this.props.handleQuestionDuplicate(this.state.id)}
            />
          </div>
          <i
            className="fas fa-trash danger-hover c-pointer"
            onClick={this.props.handleQuestionRemove(this.state.id)}
          />
        </div>
        <div className="form-group">
          <TextAreaField
            name="description"
            placeholder="Description..."
            rows="3"
            value={this.state.description}
            errors={this.state.errors.description}
            onChange={this.handleChange}
          />
        </div>
      </div>
    )
  }
}

export default Description
