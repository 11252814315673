import React from 'react'

const TextAreaField = ({ name, label, rows, value, placeholder, errors, onChange }) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <textarea
      className={errors ? 'form-control is-invalid' : 'form-control'}
      name={name}
      placeholder={placeholder}
      id={name}
      rows={rows}
      value={value}
      onChange={onChange}
    />
    {errors ? <small className="text-danger">{errors[0]}</small> : null}
  </div>
)

export default TextAreaField
