import React from 'react'

// Local import
import TextField from '../BasicFields/TextField'
import { updateErrors } from '../SectionField'

class Numeric extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.question.id,
      context: props.question.context,
      position: props.question.position,
      title: props.question.title,
      mandatory: props.question.mandatory,
      errors: props.question.errors || {}
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)

    return (
      this.state.title !== nextState.title ||
      this.state.mandatory !== nextState.mandatory ||
      this.state.position !== nextState.position ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleQuestionChange(this.state)
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  handleMandatoryChange = event => this.setState({ mandatory: event.target.checked })

  render() {
    return (
      <div className="nested-fields ml-2 ml-md-4">
        <div className="d-flex">
          <div className="mr-auto">
            <i className="fas fa-arrows-alt c-pointer" {...this.props.dragHandleProps} />
            {' Question numérique '}
            <i
              className="fas fa-clone text-secondary c-pointer"
              onClick={this.props.handleQuestionDuplicate(this.state.id)}
            />
          </div>
          <i
            className="fas fa-trash danger-hover c-pointer"
            onClick={this.props.handleQuestionRemove(this.state.id)}
          />
        </div>
        <TextField
          name="title"
          value={this.state.title}
          placeholder="Titre de la question..."
          errors={this.state.errors.title}
          onChange={this.handleChange}
        />
        <div className="custom-control custom-switch">
          <input
            id={`mandatory-question-${this.state.id}`}
            type="checkbox"
            className="custom-control-input custom-switch-lg"
            checked={this.state.mandatory}
            onChange={this.handleMandatoryChange}
          />
          <label className="custom-control-label" htmlFor={`mandatory-question-${this.state.id}`}>
            Réponse obligatoire
          </label>
        </div>
      </div>
    )
  }
}

export default Numeric
