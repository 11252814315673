/* eslint-disable camelcase */

import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

// Local import
import TextField from './BasicFields/TextField'
import SelectField from './BasicFields/SelectField'
import SelectUserGroupField from './BasicFields/SelectUserGroupField'
import TextAreaField from './BasicFields/TextAreaField'
import SectionField from './SectionField'

// Use an counter to avoid duplicate id generation
let idCounter = 0
const idGenerator = () => `new-${new Date().getTime() + idCounter++}`

class CollectForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.data.id,
      name: props.data.name,
      ensemble_id: props.data.ensemble_id,
      user_ids: props.data.user_ids,
      help_text: props.data.help_text || '',
      has_additional_answer: props.data.has_additional_answer || false,
      has_completion_for_additionnal: props.data.has_completion_for_additionnal,
      has_prepopulate_answers: props.data.has_prepopulate_answers,
      ensembles: props.data.ensembles,
      operators: props.data.operators,
      sections: props.data.sections,
      removed_section_ids: [],
      submitButtonText: 'Sauvegarder les modifications',
      submitButtonDisabled: false,
      errors: {}
    }
  }

  // Function that create FormData from nested object
  createFormData = (data, formData = new FormData(), parentKey = '') => {
    Object.keys(data).forEach(key => {
      const value = data[key]
      let newKey = parentKey ? `${parentKey}[${key}]` : key

      // Check if value is an array of objects
      if (Array.isArray(value) && value.length > 0) {
        newKey = `${newKey}[]`
        if (typeof value[0] === 'object')
          value.forEach(item => this.createFormData(item, formData, newKey))
        else
          value.forEach(item => formData.append(newKey, item))
      } else
        formData.append(newKey, value)
    })
    return formData
  }


  findQuestion = (sectionId, questionId) => {
    const section = this.state.sections.find(stateSection => stateSection.id === sectionId)

    return section.questions.find(question => question.id === questionId)
  }

  formatForm = () => {
    const formatedForm = JSON.parse(JSON.stringify(this.state))

    if (formatedForm.user_ids.length === 0)
      formatedForm.user_ids = ['']

    formatedForm.sections.forEach(section => {
      section.questions.forEach(question => {
        if (
          (question.options || []).length > 0 ||
          (question.removed_option_ids || []).length > 0
        ) {
          const removed_options = (question.removed_option_ids || []).map(id => ({ id, _destroy: true }))

          question.options.forEach(option => {
            delete option.hidden
          })

          question.options_attributes = question.options.concat(removed_options)
        } else
          question['options_attributes[]'] = ''

        /*
         * The question is serialized as JSON so the picture is not a File object
         * to do so we retrieve the picture from the question state
         */
        if (question.context === 'picture') {
          const pictureQuestion = this.findQuestion(section.id, question.id)
          delete question.picture
          if (pictureQuestion.changed)
            question.picture = pictureQuestion.picture
        }

        if (question.context !== 'description')
          delete question.description

        delete question.options
        delete question.removed_option_ids
        delete question.errors
      })
      const removed_questions = (section.removed_question_ids || []).map(id => ({ id, _destroy: true }))
      const questions = section.questions.concat(removed_questions)

      if (questions.length > 0)
        section.questions_attributes = questions
      else
        section['questions_attributes[]'] = ''

      delete section.questions
      delete section.removed_question_ids
      delete section.errors
    })

    const removed_sections = (formatedForm.removed_section_ids || []).map(id => ({ id, _destroy: true }))
    const sections = formatedForm.sections.concat(removed_sections)

    if (sections.length > 0)
      formatedForm.sections_attributes = sections
    else
      formatedForm['sections_attributes[]'] = ''

    delete formatedForm.sections
    delete formatedForm.removed_section_ids
    delete formatedForm.errors
    delete formatedForm.ensemble_id
    delete formatedForm.id
    delete formatedForm.operators
    delete formatedForm.ensembles
    delete formatedForm.submitButtonText
    delete formatedForm.submitButtonDisabled

    return formatedForm
  }

  handleSubmit = event => {
    event.preventDefault()
    const formatedForm = this.formatForm()

    // To post pictures we need to create a FormData object
    const result = this.createFormData(formatedForm, new FormData(), 'collect')

    this.setState({ submitButtonText: 'Patientez...', submitButtonDisabled: true })
    const token = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content')

    fetch(`/collects/${this.state.id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token
      },
      body: result
    }).then(response => response.json())
      .then(data => {
        if (data.success)
          window.location = '/collects'
        else {
          this.setState({
            errors: data.formated_errors,
            submitButtonText: 'Sauvegarder les modifications',
            submitButtonDisabled: false
          })
        }
      })
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  handleOperatorsChange = event => {
    const user_ids = Array.from(event.target.selectedOptions, option => option.value)
    this.setState({ user_ids })
  }

  handleCompletionAdditionnalChange = event => {
    this.setState({ has_completion_for_additionnal: event.target.checked })
  }

  handleHasPrepopulateAnswersChange = event => {
    this.setState({ has_prepopulate_answers: event.target.checked })
  }

  getErrors = index => () => this.state.errors[`sections[${index}]`]

  handleOnDragEnd = result => {
    const { destination, source } = result

    if (!destination)
      return

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return

    this.setState(prevState => {
      const movedSection = prevState.sections[source.index]
      const newSections = prevState.sections

      newSections.splice(source.index, 1)
      newSections.splice(destination.index, 0, movedSection)
      newSections.forEach((section, index) => {
        section.position = index
      })

      return { sections: newSections }
    })
  }

  handleClickNewSection = () => {
    const newSection = {
      id: idGenerator(),
      name: 'Section',
      position: this.state.sections.length,
      coefficient: 100,
      questions: []
    }

    this.setState(prevState => ({ sections: prevState.sections.concat(newSection) }))
  }

  handleSectionChange = index => section => {
    this.setState(prevState => {
      const { sections } = prevState

      sections[index] = section

      return { sections }
    })
  }

  onDuplicateSection = duplicateData => event => {
    event.stopPropagation()

    this.setState(prevState => {
      const data = duplicateData()

      data.position = prevState.sections.length

      return { sections: prevState.sections.concat(data) }
    })
  }

  handleRemoveSection = sectionId => event => {
    event.stopPropagation()
    this.setState(prevState => {
      let newlyRemovedSections = []
      const sections = prevState.sections.filter(section => section.id !== sectionId)

      if (!String(sectionId).includes('new-'))
        newlyRemovedSections = prevState.removed_section_ids.concat(sectionId)

      sections.forEach((section, index) => {
        section.position = index
      })

      return { sections, removed_section_ids: newlyRemovedSections }
    })
  }

  draggableSections = () => this.state.sections.map((section, index) => (
    <Draggable
      draggableId={`draggable-section-${index}`}
      key={`draggable-section-${index}`}
      index={index}
    >
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <SectionField
            key={`sections.${section.id}`}
            fieldName={`sections.${section.id}`}
            section={section}
            data={this.props.data}
            handleSectionChange={this.handleSectionChange(index)}
            handleDuplicateSection={this.onDuplicateSection}
            handleRemoveSection={this.handleRemoveSection(section.id)}
            getErrors={this.getErrors(section.id)}
            dragHandleProps={provided.dragHandleProps}
          />
        </div>
      )}
    </Draggable>
  ))


  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextField
              name="name"
              label="Nom"
              value={this.state.name}
              errors={this.state.errors.name}
              onChange={this.handleChange}
            />
            <SelectField
              name="ensemble_id"
              label="Ensemble"
              data={this.state.ensembles}
              value={this.state.ensemble_id}
              disabled
              onChange={this.handleChange}
            />
            <SelectUserGroupField
              name="user_ids"
              className="selectpicker"
              label="Opérateurs"
              data={this.state.operators}
              value={this.state.user_ids}
              multiple
              onChange={this.handleOperatorsChange}
            />
          </div>
          <div className="col">
            <TextAreaField
              name="help_text"
              label="Texte d'aide"
              rows="9"
              value={this.state.help_text}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="mb-2">
          <div className="custom-control custom-switch">
            <input
              id="has_prepopulate_answers"
              type="checkbox"
              className="custom-control-input custom-switch-lg"
              checked={this.state.has_prepopulate_answers}
              onChange={this.handleHasPrepopulateAnswersChange}
            />
            <label className="custom-control-label" htmlFor="has_prepopulate_answers">
              Pré-remplir le contrôle en cours avec les réponses du contrôle précédent
            </label>
          </div>
        </div>
        {
          this.state.has_additional_answer ? (
            <div className="mb-2">
              <div className="custom-control custom-switch">
                <input
                  id="has_completion_for_additionnal"
                  type="checkbox"
                  className="custom-control-input custom-switch-lg"
                  checked={this.state.has_completion_for_additionnal}
                  onChange={this.handleCompletionAdditionnalChange}
                />
                <label className="custom-control-label" htmlFor="has_completion_for_additionnal">
                  Proposer le contrôle supplémentaire après premier contrôle
                </label>
              </div>
            </div>) : null

        }
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Droppable droppableId="droppable-sections">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.draggableSections()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="add-section mt-3">
          <button className="btn btn-outline-primary" type="button" onClick={this.handleClickNewSection}>
            Ajouter une section
          </button>
        </div>
        <div className="row mt-2">
          <div className="col">
            <input
              type="submit"
              name="commit"
              className="btn btn-primary"
              value={this.state.submitButtonText}
              disabled={this.state.submitButtonDisabled}
            />
          </div>
        </div>
      </form>
    )
  }
}

export default CollectForm
