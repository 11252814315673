import React from 'react'

// Assets
import loadingSvg from '../../../assets/images/loading.svg'

const Loader = () => (
  <div className="text-center">
    <img className="w-25" src={loadingSvg} />
    <h2>Chargement de la collect en cours...</h2>
  </div>
)

export default Loader
