import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '../BasicFields/TextField'

const SelectionOption = ({ option, handleOptionChange, handleRemoveOption, multi }) => {
  const [content, setContent] = useState(option.content)

  useEffect(() => {
    handleOptionChange({ id: option.id, content })
  }, [content])

  const handleChange = useCallback(({ target: { name, value } }) => {
    if (name === 'content')
      setContent(value)
  }, [])

  return (
    <>
      <div className="nested-fields ml-2 ml-md-4">
        {
          multi ?<i className="far fa-square option-circle" />:<i className="far fa-circle option-circle" />
        }
        <div className="option-field">
          <TextField name="content" value={content} onChange={handleChange} />
        </div>
        <div className="option-trash">
          <i className="fas fa-trash danger-hover c-pointer" onClick={handleRemoveOption} />
        </div>
      </div >
      <div className="clearfix" />
    </>
  )
}

PropTypes.SelectionOption = {
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired,
  multi: PropTypes.bool,
  handleOptionChange: PropTypes.func.isRequired,
  handleRemoveOption: PropTypes.func.isRequired
}

PropTypes.SelectionOption.defaultProps = {
  multi: false
}

export default SelectionOption
