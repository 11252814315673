import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

class PictureField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: props.errors || {},
      files: props.files,
      disabled: false
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = this.props.getErrors()
    return (
      this.state.files !== nextState.files ||
      this.state.disabled !== nextState.disabled ||
      this.state.errors.isNew === true
    )
  }

  handleOnDrop = files => {
    const newFiles = []

    files.forEach(file => {
      newFiles.push(Object.assign(file, {
        preview: URL.createObjectURL(file),
        filename: file.name
      }))
    })
    this.setState({ files: newFiles })
    this.props.onChange(files)
  }

  openFileDialog = open => () => {
    this.setState({ disabled: true }, () => {
      open()
    })
  }

  handleOnFileDialogOpen = () => {
    this.setState({ disabled: false })
  }

  render() {
    const thumbsContainer = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    }

    const thumb = {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      padding: 4,
      boxSizing: 'border-box'
    }

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    }

    const img = {
      display: 'block',
      width: '100%',
      maxHeight: '300px'
    }

    const containPicture = this.state.files.length > 0

    const defaultCursor = containPicture ? { cursor: 'default' } : {}


    const thumbs = this.state.files.map(file => (
      <div style={thumb} key={file.filename}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
          />
        </div>
      </div>
    ))

    return (
      <Dropzone
        multiple={false}
        accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
        noClick
        noKeyboard
        onDrop={this.handleOnDrop}
        onFileDialogOpen={this.handleOnFileDialogOpen}
      >

        {({ getRootProps, getInputProps, open }) => (
          <section className={this.state.errors.picture ? 'invalid-dropzone' : ''}>
            <div style={defaultCursor} {...getRootProps({ className: 'dropzone disable' })}>
              <input {...getInputProps()} />
              <aside style={thumbsContainer}>
                {thumbs}
              </aside>
              <button
                className="btn btn-primary"
                type="button"
                disabled={this.state.disabled}
                onClick={this.openFileDialog(open)}
              >
                {containPicture ? 'Modifier' : 'Ajouter une image'}
              </button>
            </div>
            {
              Boolean(this.state.errors.picture) &&
              <small className="text-danger">
                {this.state.errors.picture[0]}
              </small>
            }
          </section>

        )}
      </Dropzone>
    )
  }
}
export default PictureField
