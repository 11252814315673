import React from 'react'

// Local import
import { updateErrors } from '../SectionField'
import PictureField from '../BasicFields/PictureField'

class Picture extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.question.id,
      context: props.question.context,
      position: props.question.position,
      picture: props.question.picture,
      changed: false,
      errors: props.question.errors || {}
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)
    return (
      this.state.picture !== nextState.picture ||
      this.state.position !== nextState.position ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleQuestionChange(this.state)
  }

  getErrors = () => this.state.errors

  handlePictureChange = files => {
    this.setState({
      picture: files[0],
      changed: true
    })
  }

  render() {
    return (
      <div className="nested-fields ml-2 ml-md-4 ">
        <div className="d-flex">
          <div className="mr-auto">
            <i className="fas fa-arrows-alt c-pointer" {...this.props.dragHandleProps} />
            {' Image '}
            <i
              className="fas fa-clone text-secondary c-pointer"
              onClick={this.props.handleQuestionDuplicate(this.state.id)}
            />
          </div>
          <i
            className="fas fa-trash danger-hover c-pointer"
            onClick={this.props.handleQuestionRemove(this.state.id)}
          />
        </div>
        <div className="form-group">
          <PictureField
            name="picture"
            value={this.state.picture}
            files={this.state.picture ? [this.state.picture] : []}
            getErrors={this.getErrors}
            onChange={this.handlePictureChange}
          />
        </div>
      </div>
    )
  }
}

export default Picture
