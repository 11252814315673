import React from 'react'
import PropTypes from 'prop-types'

import PhotoGallerySection from './PhotoGallerySection'

const PhotoGallery = ({ data }) => (
  <div className="row">
    {data.map(section => (
      <PhotoGallerySection
        key={section.id}
        title={section.title}
        photosSize={section.photosSize}
        url={section.url}
      />
    ))}
  </div>
)

PhotoGallery.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    photosSize: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired
  })).isRequired
}

export default PhotoGallery
